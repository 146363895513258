import { QIcon, Screen } from 'quasar';
import { computed, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import BannerLuxuryUrl from '@/assets/bg-luxury.svg?url';
import type { Attribute } from '@/composables/attributesRenderer';
import { type SearchResultsItemProps, useSearchResultsItem } from '@/composables/searchResultsItem';
import translationsListingBadges from '@/i18n/translations/components/listingBadges.json';
import { toThousands } from '@/utils/number';

export const useVarPool = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const searchResultsItem = useSearchResultsItem(props.value as SearchResultsItemProps);

  const { t: tGlobal } = useI18n();
  const { t: tListingBadges } = useI18n(translationsListingBadges);

  const badges = computed<Attribute[]>(() => [
    {
      icon: 'thunderbolt',
      key: 'new',
      value: tListingBadges('newlyListed'),
      visible: searchResultsItem.newlyListedVisible,
    },
    {
      icon: 'prosperty',
      key: 'exclusive',
      value: tListingBadges('exclusive'),
      visible: !!props.value.listing.listingInfo?.exclusive,
    },
    {
      key: 'unverified',
      value: tListingBadges('unverified'),
      visible: searchResultsItem.listingHasLabel('unverified'),
    },
    {
      icon: 'diamond',
      key: 'luxury',
      value: tListingBadges('luxury'),
      visible: searchResultsItem.listingHasLabel('luxurious'),
    },
    {
      icon: 'underDevelopment',
      key: 'under-development',
      value: tListingBadges('underConstruction'),
      visible: props.value.listing.isUnderConstruction,
    },
    {
      icon: 'house',
      key: 'my-home-2',
      value: tListingBadges('myHome2'),
      visible: searchResultsItem.listingHasLabel('myHome2'),
    },
  ]);

  const screenLtMdOrMap = computed(
    () => Screen.lt.md || searchResultsItem.elListingItemHasClass('listing-item--map')
  );

  const aggregates = computed<Attribute[]>(() => [
    {
      icon: screenLtMdOrMap.value ? 'size' : '',
      key: 'size',
      value: `${toThousands(props.value.listing.size)} ${tGlobal('sqm')}`,
      visible: props.value.listing.size > 0,
    },
    {
      icon: screenLtMdOrMap.value ? 'floor' : '',
      key: 'floor',
      value: searchResultsItem.getFloorsText(false),
      visible: !!searchResultsItem.getFloorsText(true),
    },
    {
      icon: screenLtMdOrMap.value ? 'bedrooms' : '',
      key: 'bedrooms',
      value: screenLtMdOrMap.value
        ? searchResultsItem.bedrooms
        : `${searchResultsItem.bedrooms} ${tGlobal('bedroomShort', searchResultsItem.bedrooms)}`,
      visible: searchResultsItem.bedrooms > 0,
    },
    {
      icon: screenLtMdOrMap.value ? 'parking' : '',
      key: 'parking',
      value: screenLtMdOrMap.value
        ? searchResultsItem.parkingSpaces
        : `${searchResultsItem.parkingSpaces} ${tGlobal(
            'parkingSpace',
            searchResultsItem.parkingSpaces
          )}`,
      visible: searchResultsItem.parkingSpaces > 0,
    },
  ]);

  const bannerComponents = computed(() => {
    const components = [];

    const bannerMyHome = [
      {
        component: QIcon,
        props: {
          color: 'info',
          name: 'house',
          size: '1rem',
        },
      },
      {
        component: 'span',
        props: {
          class: 'text-info',
          style: 'margin-left:0.25rem;font-weight:800;font-size:0.75rem',
          innerText: tListingBadges('myHome2'),
        },
      },
    ];

    const bannerLuxurious = {
      component: 'img',
      props: {
        alt: 'banner luxurious',
        src: BannerLuxuryUrl,
      },
    };

    if (searchResultsItem.listingHasLabel('luxurious')) {
      components.push(bannerLuxurious);
    } else if (searchResultsItem.listingHasLabel('myHome2')) {
      components.push(...bannerMyHome);
    }

    return components;
  });

  watch(
    searchResultsItem.elListingItemFavoritesBtnWidth,
    v => {
      document.documentElement.style.setProperty('--favorites-btn-width', v);
    },
    { immediate: true }
  );

  return {
    ...searchResultsItem,
    aggregates,
    badges,
    bannerComponents,
    screenLtMdOrMap,
    tListingBadges,
  };
};
